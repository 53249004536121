sc-chatbot-stream,
sc-chatbot-overlay,
sc-chatbot-overlay-multi-chat,
sc-chatbot-intelligence-overlay,
.sc-chatbot {

  @import "functions";
  @import "variables";
  @import "mixins";
  @import "./type";
  @import "./buttons";
  @import "./message";

  $chat-bubble-padding-x: 12px;
  $chat-bubble-padding-y: 8px;
  $chat-bubble-radius: 6px;

  :host:last-child {
    display: flex;
  }

  .from-server~ :host.from-server .sender {
    display: none;
  }

  ::ng-deep .badge {
    margin-right: 10px;
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    padding: 0.35em 1em;
    margin-bottom: 6px;
  }

  .sender {
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 16px;
  }

  .chatbot-inputarea {
    border-top: 1px solid $neutral-mid;
  }

  .message-wrapper {
    display: flex;
    align-items: flex-start;
    overflow: hidden;

    margin-bottom: 12px;
    margin-top: 15px;
  }

  .avatar {
    margin-top: auto;
  }
}
