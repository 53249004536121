// Cards

$card-spacer-y:                     2rem;
$card-spacer-x:                     2rem;

$card-spacer-y-sm:                  1.2rem;
$card-spacer-x-sm:                  1.2rem;

$card-spacer-y-lg:                  4rem;
$card-spacer-x-lg:                  4rem;

$card-spacer-y-xl:                  5.6rem;
$card-spacer-x-xl:                  5.6rem;

$card-border-width:                 $border-width !default;
$card-border-radius:                $border-radius !default;
$card-border-color:                 $border-color;
$card-inner-border-radius:          subtract($card-border-radius, $card-border-width) !default;
$card-cap-bg:                       transparent;
$card-cap-color:                    null !default;
$card-height:                       null !default;
$card-color:                        null !default;
$card-bg:                           $white !default;

$card-box-shadow:                   $box-shadow-xs;
$card-box-shadow-lg:				$box-shadow-lg;
$card-box-shadow-xl:                0 .4rem .6rem -.4rem rgba($neutral-dark, 0.15), 0 0 0 .1rem rgba($neutral-lighter, .5), 0 2.4rem 6.4rem 0 rgba($neutral-dark, .075);

$card-box-shadow-hover:             $box-shadow-xs-hover;

$card-img-overlay-padding:          1.25rem !default;

$card-group-margin:                 $grid-gutter-width / 2 !default;
$card-deck-margin:                  $card-group-margin !default;

$card-columns-count:                3 !default;
$card-columns-gap:                  1.25rem !default;
$card-columns-margin:               $card-spacer-y !default;
