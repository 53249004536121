$font-family-sans-serif:      'Circular Std', sans-serif;
$font-family-serif:           'Larish Neue', serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$font-size-root:              null;
$font-size-base:              1.6rem;
$font-size-xs:                1.15rem;
$font-size-sm:                1.3rem;
$font-size-lg:                1.8rem;


$font-weight-normal:          400;
$font-weight-bold:            500;

$font-weight-base:            $font-weight-normal;

$line-height-base:            1.5;
$line-height-sm:              1.5;
$line-height-lg:              2;

$link-color:                              $primary-base;
$link-decoration:                         none;
$link-hover-color:                        $primary-dark;
$link-hover-decoration:                   underline;

// https://codesandbox.io/s/clamp-linear-intepolation-based-on-viewport-width-builder-xgkft?from-embed
// min viewport width 375px
// max viewport width 1320px
// 1rem = 10px


$heading-xl-rfs:    #{"clamp(4rem, 3.3651rem + 1.6931vw, 5.6rem)"};
$heading-lg-rfs:    #{"clamp(3.6rem, 3.1238rem + 1.2698vw, 4.8rem)"};
$heading-h1-rfs:    #{"clamp(3rem, 2.6032rem + 1.0582vw, 4rem)"};
$heading-h2-rfs:    #{"clamp(2.4rem, 2.0825rem + 0.8466vw, 3.2rem)"};
 
$heading-h3-rfs:    #{"clamp(2.1rem, 1.8222rem + 0.7407vw, 2.5rem)"};
$heading-h4-rfs:    #{"clamp(1.8rem, 1.5619rem + 0.6349vw, 2.1rem)"};
$heading-h5-rfs:    #{"clamp(1.6rem, 1.5405rem + 0.1587vw, 1.75rem)"};
$heading-h6-rfs:    #{"clamp(1.6rem, 1.0413rem + 0.4233vw, 1.6rem)"};

$lead-rfs:          #{"clamp(1.6rem, 1.5405rem + 0.1587vw, 1.75rem)"};
$text-large-rfs:    #{"clamp(1.6rem, 1.5008rem + 0.2646vw, 1.85rem)"};
$text-larger-rfs:   #{"clamp(1.6rem, 1.4413rem + 0.4233vw, 2rem)"};
