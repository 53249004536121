// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.tag {
  display: inline-block;
  padding: $tag-padding-y $tag-padding-x;
  font-size: $tag-font-size;
  font-weight: $tag-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border: 1px solid $tag-border-color;
  @include border-radius($tag-border-radius);
  @include transition($tag-transition);

  @at-root a#{&} {
    @include hover-focus() {
      text-decoration: none;
    }
  }

  // Empty tags collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for tags in buttons
.btn .tag {
  position: relative;
  top: -1px;
}

// Pill tags
//
// Make them extra rounded with a modifier to replace v3's tags.

.tag-pill {
  padding-right: $tag-pill-padding-x;
  padding-left: $tag-pill-padding-x;
  @include border-radius($tag-pill-border-radius);
}

.tag-clip-text{
  max-width: $tag-max-width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag-list {

  .list-inline-item { 
    line-height: 1;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

}