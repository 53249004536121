//
// Base styles
//

.alert {
  position: relative;
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border: $alert-border-width solid transparent;
  border-radius: $alert-border-radius;

  :last-child {
    margin-bottom: 0;
  }
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}



// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: $close-font-size + $alert-padding-x * 2;

  // Adjust close link position
  .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: $alert-padding-y $alert-padding-x;
    color: inherit;
  }
}


// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

// @each $color, $value in $theme-colors {
//   .alert-#{$color} {
//     @include alert-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
//   }
// }

.alert-secondary {
  @include alert-variant($background: $neutral-lightest, $border: $neutral-lightest, $color: $neutral-darker);
}

.alert-primary {
  @include alert-variant($background: $primary-lighter, $border: $primary-lighter, $color: $primary-dark);
}

.alert-success {
  @include alert-variant($background: $positive-lighter, $border: $positive-lighter, $color: $positive-darker);
}

.alert-warning {
  @include alert-variant($background: $yellow-lighter, $border: $yellow-lighter, $color: $yellow-darker);
}

.alert-error {
  @include alert-variant($background: $negative-lighter, $border: $negative-lighter, $color: $negative-darker);
}  