h2, .h2
{
    font-size: 26px;
}

h3, .h3
{
    font-size: 17px;
}

.small 
{
    font-size: 1.4rem;
}

.text-muted
{
    color: #3C3C43 !important;
    opacity: .7;
}