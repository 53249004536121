// Progress bars

$progress-height:                   .4rem;
$progress-font-size:                $font-size-base * .75;
$progress-bg:                       $neutral-lighter;
$progress-border-radius:            $border-radius-sm;
$progress-box-shadow:               none;
$progress-bar-color:                $white;
$progress-bar-bg:                   $primary-base;
$progress-bar-animation-timing:     1s linear infinite !default;
$progress-bar-transition:           width .6s ease !default;
