.bg-primary-darker { background-color: $primary-darker !important }
.bg-primary-dark { background-color: $primary-dark !important }
.bg-primary-base { background-color: $primary-base !important }
.bg-primary-light { background-color: $primary-light !important }
.bg-primary-lighter { background-color: $primary-lighter !important }
.bg-primary-lightest { background-color: $primary-lightest !important }
.bg-white { background-color: $white !important}

.bg-neutral-darker { background-color: $neutral-darker !important }
.bg-neutral-dark { background-color: $neutral-dark !important }
.bg-neutral-base { background-color: $neutral-base !important }
.bg-neutral-mid { background-color: $neutral-mid !important }
.bg-neutral-light { background-color: $neutral-light !important }
.bg-neutral-lighter { background-color: $neutral-lighter !important }
.bg-neutral-lightest { background-color: $neutral-lightest !important }

.bg-negative-darker { background-color: $negative-darker !important }
.bg-negative-dark { background-color: $negative-dark !important }
.bg-negative-base { background-color: $negative-base !important }
.bg-negative-mid { background-color: $negative-mid !important }
.bg-negative-light { background-color: $negative-light !important }
.bg-negative-lighter { background-color: $negative-lighter !important }
.bg-negative-lightest { background-color: $negative-lightest !important }

.bg-positive-darker { background-color: $positive-darker !important }
.bg-positive-dark { background-color: $positive-dark !important }
.bg-positive-base { background-color: $positive-base !important }
.bg-positive-mid { background-color: $positive-mid !important }
.bg-positive-light { background-color: $positive-light !important }
.bg-positive-lighter { background-color: $positive-lighter !important }
.bg-positive-lightest { background-color: $positive-lightest !important }

.bg-pastel-blue         { background-color: $pastel-blue !important;        }
.bg-pastel-petrol       { background-color: $pastel-petrol !important;      }
.bg-pastel-mint-light   { background-color: $pastel-mint-light !important;  }
.bg-pastel-mint         { background-color: $pastel-mint !important;        }
.bg-pastel-mint-dark    { background-color: $pastel-mint-dark !important;   }
.bg-pastel-green        { background-color: $pastel-green !important;       }
.bg-pastel-olive        { background-color: $pastel-olive !important;       }
.bg-pastel-yellow       { background-color: $pastel-yellow !important;      }
.bg-pastel-lime         { background-color: $pastel-lime !important;        }
.bg-pastel-yellow-light { background-color: $pastel-yellow-light !important;}
.bg-pastel-brown        { background-color: $pastel-brown !important;       }
.bg-pastel-rose         { background-color: $pastel-rose !important;        }
.bg-pastel-rose-dark    { background-color: $pastel-rose-dark !important;   }
.bg-pastel-orange-dark  { background-color: $pastel-orange-dark !important; }
.bg-pastel-red          { background-color: $pastel-red !important;         }
.bg-pastel-orange       { background-color: $pastel-orange !important;      }
.bg-pastel-violet       { background-color: $pastel-violet !important;      }
.bg-pastel-purple       { background-color: $pastel-purple !important;      }