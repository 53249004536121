.img-container {
  position: relative;
  overflow: hidden;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.hover-zoom {

  img {
    transition: transform .3s ease-in-out;
  }

  &:hover,
  &:focus {
    img {
      transform: scale(1.04);
    }
  }

}
