// Modals

// Padding applied to the modal body
$modal-inner-padding:               2rem;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between:       1rem;

$modal-dialog-margin:               1.6rem;
$modal-dialog-margin-y-sm-up:       1.75rem !default;

$modal-title-line-height:           $line-height-base;

$modal-content-color:               null !default;
$modal-content-bg:                  $white !default;
$modal-content-border-color:        $border-color;
$modal-content-border-width:        $border-width;
$modal-content-border-radius:       $border-radius;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs:       $box-shadow-xs;
$modal-content-box-shadow-sm-up:    $box-shadow-lg;

$modal-backdrop-bg:                 $neutral-darker;
$modal-backdrop-opacity:            .4 !default;
$modal-header-border-color:         $border-color !default;
$modal-footer-border-color:         $border-color;
$modal-header-border-width:         $modal-content-border-width !default;
$modal-footer-border-width:         $modal-header-border-width !default;
$modal-header-padding-y:            1.6rem;
$modal-header-padding-x:            2rem;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl:                          1140px !default;
$modal-lg:                          800px !default;
$modal-md:                          600px !default;
$modal-sm:                          300px !default;

$modal-fade-transform:              translate(0, -50px) !default;
$modal-show-transform:              none !default;
$modal-transition:                  transform .3s ease-out !default;
$modal-scale-transform:             scale(1.02) !default;

$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1060 !default;