// tags

$tag-font-size:                   $font-size-root;
$tag-font-weight:                 $font-weight-normal;
$tag-padding-y:                   .35em;
$tag-padding-x:                   .5em;
$tag-border-color:                $neutral-light;
$tag-border-radius:               $border-radius-sm;
$tag-max-width:                   400px;

$tag-transition:                  $btn-transition;
// $tag-focus-width:                 $input-btn-focus-width !default;

$tag-pill-padding-x:              .6em;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$tag-pill-border-radius:          10rem;
