// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: $badge-font-size;
  font-weight: $badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  @include border-radius($badge-border-radius);
  @include transition($badge-transition);

  @at-root a#{&} {
    @include hover-focus() {
      text-decoration: none;
    }
  }

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-pill {
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

// @each $color, $value in $theme-colors {
//   .badge-#{$color} {
//     @include badge-variant($value);
//   }
// }

.badge-primary {
  @include badge-variant($background: $primary-base, $color: $white);
}

.badge-secondary {
  @include badge-variant($background: $neutral-base, $color: $white);
}

.badge-success {
  @include badge-variant($background: $positive-base, $color: $white);
}

.badge-danger {
  @include badge-variant($background: $negative-base, $color: $white);
}

.badge-light {
  @include badge-variant($background: $neutral-light, $color: $black);

  &.status-danger {
    &:before {
      content: '•';
      line-height: 1;
      margin-right: .4em;
      color: $negative-base;
    }
  }
  
  &.status-success {
    &:before {
      content: '•';
      line-height: 1;
      margin-right: .4em;
      color: $positive-base;
    }
  }

}

.badge-dark {
  @include badge-variant($background: $neutral-darker, $color: $white);
}

.badge-warning {
  @include badge-variant($background: $yellow-base, $color: $black);
}

.badge-primary-soft {
  @include badge-variant($background: $primary-light, $color: rgba($primary-base, .75));
}

.badge-secondary-soft {
  @include badge-variant($background: $neutral-lighter, $color: $neutral-dark);
}

.badge-success-soft {
  @include badge-variant($background: $positive-lighter, $color: $positive-dark);
}

.badge-danger-soft {
  @include badge-variant($background: $negative-lighter, $color: $negative-dark);
}

.badge-warning-soft {
  @include badge-variant($background: $yellow-light, $color: $yellow-darker);
}



