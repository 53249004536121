// Badges

$badge-font-size:                   80%;
$badge-font-weight:                 $font-weight-bold;
$badge-padding-y:                   .35em;
$badge-padding-x:                   .5em;
$badge-border-radius:               $border-radius-sm;

$badge-transition:                  $btn-transition;
// $badge-focus-width:                 $input-btn-focus-width !default;

$badge-pill-padding-x:              .6em;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius:          10rem;
