// Configuration
@import "functions";
@import "variables";
@import "mixins";
@import "utilities";

@import "fonts";

@import "type";
@import "borders";
@import "buttons";
@import "background-colors";
@import "box-shadows";
@import "tables";
@import "alert";

// Layout & components
@import "reboot";
// @import "images";
@import "containers";
@import "grid";
@import "forms";
@import "custom-forms";
@import "dropdown";
@import "input-group";
@import "avatar";
@import "badge";
@import "breadcrumb";
@import "close";
@import "tooltips";
@import "card";
@import "progress";
@import "image";
@import "transitions";
@import "modal";
@import "list-group";
@import "nav";
@import "navbar";
@import "scrollbar";
@import "tag";

// Utilities
@import "utilities/api";
@import "utilities/alignment";
// scss-docs-end import-stack



//DOC Styles

aside a {
  // font-weight: $font-weight-bold;  
  color: $neutral-base;
  padding: .4rem 0;
  display: inline-block;
}