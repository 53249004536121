/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.scrollbar-minimal::-webkit-scrollbar {
    background-color:#fff;
    width:16px
}

/* background of the scrollbar except button or resizer */
.scrollbar-minimal::-webkit-scrollbar-track {
    background-color:#fff
}
.scrollbar-minimal::-webkit-scrollbar-track:hover {
    background-color:#fff
}

/* scrollbar itself */
.scrollbar-minimal::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:8px;
    border:5px solid #fff
}

.scrollbar-minimal::-webkit-scrollbar-thumb:hover {
    background-color:#babac0;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar-minimal::-webkit-scrollbar-button {display:none}

.scrollbar-none::-webkit-scrollbar {
        display: none;
      
}