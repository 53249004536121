@font-face {
  font-family: 'Circular Std';
  src: url('/assets/fonts/circular/CircularStd-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('/assets/fonts/circular/CircularStd-Bold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Larish Neue';
  src: url('/assets/fonts/larish-neue/LarishNeue.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}