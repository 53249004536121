// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s, `<ul>`s or `<ol>`s.

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: $nav-link-padding-y $nav-link-padding-x;
  text-decoration: if($link-decoration == none, null, none);
  cursor: pointer;

  @include hover-focus() {
    text-decoration: none;
  }

  // Disabled state lightens text
  &.disabled {
    color: $nav-link-disabled-color;
    pointer-events: none;
    cursor: default;
  }
}

//
// Tabs
//

.nav-tabs {
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

  .nav-link {
    margin-bottom: -$nav-tabs-border-width;
    background-color: transparent;
    border: $nav-tabs-border-width solid transparent;
    cursor: pointer;
    
    @include border-top-radius($nav-tabs-border-radius);

    @include hover-focus() {
      // Prevents active .nav-link tab overlapping focus outline of previous/next .nav-link
      isolation: isolate;
      border-color: $nav-tabs-link-hover-border-color;
    }

    &.disabled {
      color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-tabs-link-active-color;
    background-color: $nav-tabs-link-active-bg;
    border-color: $nav-tabs-link-active-border-color;
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -$nav-tabs-border-width;
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
  }
}


//
// Pills
//

.nav-pills {
  .nav-link {
    background: none;
    border: 0;
    @include border-radius($nav-pills-border-radius);
  }

  .nav-link.active,
  .show > .nav-link {
    color: $nav-pills-link-active-color;
    background-color: $nav-pills-link-active-bg;
  }
}


//
// Justified variants
//

.nav-fill {
  > .nav-link,
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  > .nav-link,
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}


.nav-tabs {
  .nav-item {
    margin-left: $nav-tabs-link-margin-x;
    margin-right: $nav-tabs-link-margin-x;
  }

  .nav-link {
    padding: $nav-tabs-link-padding-y 0;
    border-bottom: $nav-tabs-link-active-border-width solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;

    &:not(.active) {
      color: $neutral-darker;

      &:hover {
        color: $neutral-darker;
      }
    }
  }

  .nav-item:first-child {
    margin-left: 0;
  }

  .nav-item:last-child {
    margin-right: 0;
  }

  // Removes the primary color underline from dropdowns in .nav-tabs
  .nav-item.show .nav-link {
    border-color: transparent;
  }
}

//
// Theme =====================================
//

// Nav overflow
//
// Allow links to overflow and make horizontally scrollable

.nav-overflow {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: auto;

  padding-bottom: 1px; // to prevent active links border bottom from hiding

  // Hide scrollbar

  &::-webkit-scrollbar {
    display: none;
  }
}

// Creates a small version of the .nav-tabs

.nav-tabs-sm {
  font-size: $nav-tabs-sm-font-size;

  .nav-item {
    margin-left: $nav-tabs-sm-link-margin-x;
    margin-right: $nav-tabs-sm-link-margin-x;
  }

  .nav-link {
    // Calculates the exact padding necessary to vertically fill the .card-header
    padding-top: (divide($font-size-base, $nav-tabs-sm-font-size) * $nav-tabs-link-padding-y);
    padding-bottom: (divide($font-size-base, $nav-tabs-sm-font-size) * $nav-tabs-link-padding-y);
  }
}

// Creates a small version of the .nab

.nav-sm {
  .nav-link {
    font-size: $font-size-sm;
  }
}

// Nav + button group
//
// Change the look of .btn-white when .active

.nav.btn-group {
  .btn-white.active {
    background-color: $primary-base;
    border-color: $primary-base;
    color: $white;
  }
}


// 
// nav.scss
// Extended from Bootstrap
//

// 
// Bootstrap Overrides =====================================
//

// Changing nav tabs to be bottom highlight style

.nav-tabs {

  .nav-link {

    &.active {
      font-weight: $font-weight-bold;
    }

    &:not(.active) {

      color: $neutral-darker;

      &:hover {
        color: $neutral-dark;
      }
    }

  }  

}
