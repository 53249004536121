// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover {
    color: $color;
    text-decoration: none;
    background-color: $hover-background;
    border-color: $hover-border;
  }

  &:focus,
  &.focus {
    background-color: $hover-background;
    border-color: $hover-border;
    // box-shadow: 0 0 0 2px rgba($background, .25);
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: $background;
    border-color: $border;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    background-color: $active-background;
 
    border-color: $active-border;

    &:focus {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 2px rgba($background, .25);
    }
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}
