// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:                   1.6rem;
$alert-padding-x:                   2rem;
$alert-margin-bottom:               1.6rem;
$alert-border-radius:               $border-radius;
$alert-link-font-weight:            $font-weight-bold;
$alert-border-width:                $border-width;

$alert-bg-level:                    -10;
$alert-border-level:                -9;
$alert-color-level:                 6;