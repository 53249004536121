
.ratio {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    content: "";
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@each $key, $ratio in $aspect-ratios {
  .ratio-#{$key} {
    padding-top: #{$ratio};
  }
}
