$nav-link-padding-y:                .8rem !default;
$nav-link-padding-x:                1.6rem !default;
$nav-link-disabled-color:           $neutral-dark !default;

$nav-tabs-border-color:             $neutral-light !default;
$nav-tabs-border-width:             0 !default;
$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-hover-border-color:  $neutral-lighter $neutral-lighter $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        $neutral-darker !default;
$nav-tabs-link-active-bg:           transparent !default;
$nav-tabs-link-active-border-color: $neutral-light $neutral-light $primary-base !default;
$nav-tabs-link-margin-x:			$spacer * 3 !default;
$nav-tabs-link-padding-y:			$spacer * .5 !default;
$nav-tabs-link-active-border-width: .2rem !default;
$nav-tabs-sm-font-size: 			$font-size-sm !default;
$nav-tabs-sm-link-margin-x:			$spacer !default;

$nav-pills-border-radius:           $border-radius !default;
$nav-pills-link-active-color:       $black !default;
$nav-pills-link-active-bg:          $white !default;

$nav-divider-color:                 $neutral-lighter !default;
$nav-divider-margin-y:              $spacer * .5 !default;

$navbar-padding-y:                  $spacer * .5 !default;
$navbar-padding-x:                  $spacer !default;

$navbar-nav-link-padding-x:         .5rem !default;


$navbar-brand-font-size:            $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) * .5 !default;

$navbar-toggler-padding-y:          .25rem !default;
$navbar-toggler-padding-x:          .75rem !default;
$navbar-toggler-font-size:          $font-size-lg !default;
$navbar-toggler-border-radius:      $btn-border-radius !default;

$navbar-nav-scroll-max-height:      75vh !default;

$navbar-dark-color:                 rgba($white, .5) !default;
$navbar-dark-hover-color:           rgba($white, .75) !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color:  rgba($white, .1) !default;

$navbar-light-color:                rgba($black, .5) !default;
$navbar-light-hover-color:          rgba($black, .7) !default;
$navbar-light-active-color:         rgba($black, .9) !default;
$navbar-light-disabled-color:       rgba($black, .3) !default;
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($black, .1) !default;

$navbar-light-brand-color:                $navbar-light-active-color !default;
$navbar-light-brand-hover-color:          $navbar-light-active-color !default;
$navbar-dark-brand-color:                 $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color:           $navbar-dark-active-color !default;