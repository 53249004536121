@mixin badge-variant($background, $color) {
  color: $color;
  background-color: $background;

  @at-root a#{&} {
    @include hover-focus() {
      color: $color;
      background-color: darken($background, 10%);
    }

    // &:focus,
    // &.focus {
    //   outline: 0;
    //   box-shadow: 0 0 0 $badge-focus-width rgba($bg, .5);
    // }
  }
}
