%heading {
  color: $black;
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-bold;
  line-height: 1.2;
}

%heading-serif {
  color: $primary-base;
  font-family: $font-family-serif;
  font-weight: $font-weight-bold;
  line-height: 1.2;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.heading-xl, .heading-lg 
{ @extend %heading }

.heading-xl, h1.heading-xl, h2.heading-xl { 
  font-size: $heading-xl-rfs; 
}

.heading-lg, h1.heading-lg, h2.heading-lg{ 
  font-size: $heading-lg-rfs; 
}

h1, .h1 {
  font-size: $heading-h1-rfs;
}
h2, .h2 { 
  font-size: $heading-h2-rfs;
}
h3, .h3 {
  font-size: $heading-h3-rfs;
}
h4, .h4 {
  font-size: $heading-h4-rfs;
}
h5, .h5 {
  font-size: $heading-h5-rfs;
  margin-bottom: 1.6rem;
}
h6, .h6 {
  font-size: $heading-h6-rfs;
  margin-bottom: 1.6rem;
}



h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.heading-xl, .heading-lg {

  &.heading-serif {
    @extend %heading-serif;
  }

}

.default {
  font-size: $font-size-base;
}

.lead {
  font-size: $lead-rfs;
  font-weight: $font-weight-normal;
}

.text-muted {
  color: $text-muted;
}

.text-right {
  text-align: right;
}

.text-primary {
  color: $primary-base;
}

.text-success {
  color: $positive-dark;
}

.text-danger {
  color: $negative-dark;
}

.text-black {
  color: $black;
}

.pretitle {
  font-size: $font-size-xs;
  color: $neutral-base;
  letter-spacing: .1em;
  margin-bottom: 0;
  text-transform: uppercase;
  display: block;
}

.heading-uppercase {
  letter-spacing: .2rem;
  text-transform: uppercase;
  color: $primary-base;
  font-size: 1.3rem; 
}

.small {
  font-size: $font-size-sm;
}

.smaller {
  font-size: $font-size-xs;
}

.large {
  font-size: $text-large-rfs;
}

.larger {
  font-size: $text-larger-rfs;
}

//
// Lists
//

.list-unstyled {
  @include list-unstyled();
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled;
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}

.arrow {
  &.right {
    &:after {
      content: '';
      display: inline-block;
      width: 7px;
      height: 7px;
      border-top: 2px solid $primary-base;
      border-right: 2px solid $primary-base;
      transform: rotate(45deg) translateY(-1px);
      margin-left: .5rem;
    }
  }
}

.link-arrow {
  font-weight: $font-weight-bold;
  color: $primary-base;
  cursor: pointer;

  font-size: 1.3rem;

  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }

  &:hover {
    text-decoration: none;
    color: $primary-dark;
  }

  &.text-uppercase {
    letter-spacing: .2rem;
  }

  &.right {
    &:after {
      content: '';
      display: inline-block;
      width: 7px;
      height: 7px;
      border-top: 2px solid $primary-base;
      border-right: 2px solid $primary-base;
      transform: rotate(45deg) translateY(-2px);
      margin-left: .5rem;
    }
  }

  &.left {
    &:before {
      content: '';
      display: inline-block;
      width: 7px;
      height: 7px;
      border-top: 2px solid $primary-base;
      border-right: 2px solid $primary-base;
      transform: rotate(-135deg) translateY(2px);
      margin-right: .5rem;
    }
  }

  &.down {
    &:after {
      content: '';
      display: inline-block;
      width: 7px;
      height: 7px;
      border-top: 2px solid $primary-base;
      border-right: 2px solid $primary-base;
      transform: rotate(135deg) translateY(2px);
      margin-left: .75rem;
    }
  }

  &.left:hover,
  &.top:hover,
  &.down:hover,
  &.right:hover {
    &:after {
      border-color: $primary-dark;
    }
  }
}

.oneline {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
