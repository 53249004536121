// .btn {
//   border-radius: 100px;
// }

.btn:not(.btn-lg):not(.btn-sm) {
  padding-top: ($btn-padding-y * .85);
} 


// .btn-primary {
  
//   box-shadow: none;

  
//   &:hover {
//     background: $primary-dark;

//   }
//   &:focus, &.focus {
//     background: $primary-dark;
//   }
// }

.btn-secondary {
  //color: $primary-base;
  // box-shadow: none;
  // &:hover, &:focus, &.focus {
  //   background: $primary-dark;
  //   color: $primary-base;
  //   box-shadow: none;
  //   border-color: $primary-dark;
  // }

}


// .btn-secondary:not(:disabled):not(.disabled):active:focus, 
// .btn-secondary:not(:disabled):not(.disabled).active:focus, 
// .show > .btn-secondary.dropdown-toggle:focus {
//   box-shadow: none;
// }

// .btn-secondary:not(:disabled):not(.disabled):active, 
// .btn-secondary:not(:disabled):not(.disabled).active {
//   box-shadow: none;
//   background: #D9E8F9;
//   color: $primary-dark;
//   border-color: #D9E8F9;
// }

.btn-success {
  color: $white;

  &:hover, &:active {
    color: $white !important;
  }
}

// Button white

.btn-white {
  border-color: $neutral-light;

  &:hover, &:focus {
    background-color: $neutral-lightest;
    border-color: $neutral-mid;
  }
}

.btn-primary-inverted {
  border-color: $white;
  color: $primary-base; 
  background-color: $white;

  &:hover, &:focus {
    background-color: $white;
    border-color: $white;
    color: $primary-dark;
  }
}


.btn-group-toggle .btn-white:not(:disabled):not(.disabled):active, 
.btn-group-toggle .btn-white:not(:disabled):not(.disabled).active {
  background-color: $input-bg;
  border-color: $input-focus-border-color;
  color: $primary-base;
}

.btn-group-toggle .btn-white:focus,
.btn-group-toggle .btn-white.focus {
  box-shadow: none;
}


// Button outline secondary

.btn-outline-secondary {
  &:not(:hover):not([aria-expanded="true"]):not([aria-pressed="true"]){
    border-color: $neutral-mid;
  }
}


// Button rounded
//
// Creates circle button variations

.btn-rounded-circle {
  padding-left: 0;
  padding-right: 0;
  border-radius: 50%;
}
.btn-rounded-circle.btn-lg {
  // width: calc(1em * #{$btn-line-height-lg} + #{$input-btn-padding-y-lg * 2 } + #{$btn-border-width} * 2);
}
.btn-rounded-circle.btn-sm {
  width: 34px;
  height: 34px;
}


// Button group
//
// Prevent buttons from jittering on hover

.btn-group .btn + .btn {
  margin-left: 0;
}

.send-button {
  i {
    display: block;
    transform: translateX(0.2rem) translateY(0.145rem);
  }
}