@import "skillconomy";
@import "scui-chatbot-theme.scss";
@import "@fortawesome/fontawesome-svg-core/styles.css";

// body {
//   margin: 0;
//   padding: 0;
// }

body {
  // background: rgba($neutral-lightest, 1);
}

// .oneline
// {
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
// }

// #container {
//   position: absolute;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
// }

// .scroll-dummy {
//   min-height: 200vh;
// }

body {
  overflow-x: hidden;
  background-color: $neutral-lightest;
}

.container-xl {
  max-width: 1000px;
}

.container {
  max-width: 800px;
}

.content {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  @include media-breakpoint-up(md) {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }
}

 

// h3, .h3 {
//   letter-spacing: -0.015rem;
//   line-height: 1.3;
//   margin-bottom: 2rem;
// }

// h3.underlined, .h3.underlined {
//   border-bottom: 2px solid $border-color;
//   padding-bottom: 1rem;
// }

// .text-success {
//   // color: darken($positive-base, 20%) !important;
// }

// .shadow-light-lg {
//   // box-shadow: $box-shadow-light-lg;
// }

// a .shadow-light-lg:hover {
//   // box-shadow: $box-shadow-light-lg-hover;
// }


// .lift {
//   transition: box-shadow .25s ease !important;
//   transform: none !important;
// }

// i.size-1 {
//   font-size: .75rem;
//   line-height: 1;
//   vertical-align: 1px;
// }

// i.size-2 {
//   font-size: 1.25rem;
//   line-height: 1;
//   vertical-align: middle;
// }

// .arrow {
//   &.right {
//     &:after {
//       content: '';
//       display: inline-block;
//       width: 7px;
//       height: 7px;
//       // border-top: 2px solid $primary-base
//       // border-right: 2px solid $primary-base
//       transform: rotate(45deg) translateY(-1px);
//       margin-left: .5rem;
//     }
//   }
// }

// .link-arrow {
//   font-weight: $font-weight-bold;
//   color: $primary-base
//   font-size: $small-font-size;
//   cursor: pointer;

//   &:hover {
//     text-decoration: none;
//     color: $primary-dark;
//   }

//   &.right {
//     &:after {
//       content: '';
//       display: inline-block;
//       width: 7px;
//       height: 7px;
//       border-top: 2px solid $primary-base
//       border-right: 2px solid $primary-base
//       transform: rotate(45deg) translateY(-1px);
//       margin-left: .5rem;
//     }
//   }

//   &.left {
//     &:before {
//       content: '';
//       display: inline-block;
//       width: 7px;
//       height: 7px;
//       border-top: 2px solid $primary-base
//       border-right: 2px solid $primary-base
//       transform: rotate(-135deg) translateY(2px);
//       margin-right: .5rem;
//     }
//   }

//   &.down {
//     &:after {
//       content: '';
//       display: inline-block;
//       width: 7px;
//       height: 7px;
//       border-top: 2px solid $primary-base
//       border-right: 2px solid $primary-base
//       transform: rotate(135deg) translateY(2px);
//       margin-left: .75rem;
//     }
//   }

//   &.left:hover,
//   &.top:hover,
//   &.down:hover,
//   &.right:hover {
//     &:after {
//       border-color: $primary-dark;
//     }
//   }

// }

.auth-card {
  position: relative;
  @include make-col-ready();
  @include make-col(12);

  @include media-breakpoint-up(md) {
    max-width: 524px;
  }

  .logo {
    max-width: 184px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 5rem;
  }

 
}

.auth-blob {
  position: absolute;
  left: -50%;
  top: -50%;
  width: 110%;
  max-width: 550px;
  z-index: -1;

  img {
    width: 100%;
  }

}

.auth-illu {
  @include media-breakpoint-up(md) {
    position: absolute;
    right: 0;
    bottom: -10%;
  }
}

 

// .image-container {
//   position: relative;
//   height: 0;
//   padding-bottom: (345 / 1050) * 100%; 

//   img {
//     position: absolute;
//     left: 0;
//     top: 0;
//     height: 100%;
//     width: 100%;
//     object-fit: cover;
//   }
// }

// // body:after { /*This adds a label in the top-left corner that displays the current media query state.*/
// //   background: red;
// //   color: #fff;
// //   content: "undefined";
// //   bottom: 0;
// //   padding: .1em 1em;
// //   font-size: 16px;
// //   position: fixed;
// //   text-align: center;
// //   right: 0;
// //   z-index: 99;
// //   user-select: none;
// //   display: flex;
// //   order: 0;

// //   @include media-breakpoint-up(xs) {
// //     background: orange;
// //     content: "xs";
// //   }
// //   @include media-breakpoint-up(sm)  {
// //     background: purple;
// //     content: "sm";
// //   }
// //   @include media-breakpoint-up(md) {
// //     background: green;
// //     content: "md";
// //   }
// //   @include media-breakpoint-up(lg) {
// //     background: blue;
// //     content: "lg";
// //   }
// //   @include media-breakpoint-up(xl) {
// //     background: black;
// //     content: "xl";
// //   }
// // }


.clickable {
  outline: 0;
  cursor: pointer;
}

// .card-cta-primary {
//   @extend .shadow-light-lg;
//   background: $primary-base

// }

// .card-cta-primary-dark {
//   @extend .shadow-light-lg;
//   background: $primary-dark;

// }

// .tag-list .badge {
//   margin-right: .5rem;
//   margin-bottom: 1rem;
//   max-width: 400px;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

.chat-modal-body {

  & .modal-body {
    height: 100%;
    padding: 0;

    sc-chatbot-stream {
      height: calc(100%);
    }

    .tag-list {
      line-height: 0;
    }

    .tag {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;

      &.status-green::before {
        content: "•";
        line-height: 1;
        margin-right: 0.4em;
        color: $positive-base;
      }

      &.status-yellow::before {
        content: "•";
        line-height: 1;
        margin-right: 0.4em;
        color: $yellow-base;
      }

      &.optional {
        opacity: .75;
      }

      svg.tag-icon {
        display: inline-block;
        height: .85em;
        margin-right: .8rem;
        fill: $neutral-dark;
      }
    }


    .requirements {

      border-left: 2px solid $neutral-dark;
      padding-left: 1.6rem;
      padding-top: .8rem;
      padding-bottom: 0rem;
      margin-bottom: 1.6rem;

      .requirements-headline {
        font-weight: 600;
        margin-bottom: .8rem;

        &:not(:first-child) {
          margin-top: .8rem;
        }
      }

      .tag-list {


        &>.tag {
          margin-bottom: .8rem;
          margin-right: .8rem;
        }

        .or-condition {
          display: inline-block;
          white-space: nowrap;
          margin-bottom: .8rem;
          margin-right: .8rem;

          &>span {
            margin-right: 0.8rem;
          }

          &>span:last-child {
            margin-right: 0;
          }

        }
      }

    }
  }

  .screening-modal-body {
    & .modal-body {
      height: 100%;
      padding: 0;
    }
  }
}