
@import "functions";
@import "variables";
@import "mixins";

$chat-bubble-padding-x: 16px;
$chat-bubble-padding-y: 12px;
$chat-bubble-radius: 16px;
$message-box-shadow:         0 .7rem 1.2rem -.3rem rgba($neutral-dark, 0.1); // , 0 0 0 .1rem $neutral-lighter;

.chatbot-message {
    line-height: 1.4;
    width: 100%;
    overflow: hidden;
    will-change: height, padding;
    transform: translate3d(0, 0, 0);

    padding: 0 15px;

    &.chatbot-external-link {
        .message-content {
            background: $white;
            border: 1px solid $neutral-mid;
            overflow: visible;
            max-width: 100%;
            width: calc(100% - 13px);
        }

        a {
            position: relative;
            display: block;
            color: $black;
            &:hover {
                color: $primary-base;
                text-decoration: none;
            }

            margin: -16px;
            padding: 16px;
            padding-right: 20px;

            @include media-breakpoint-up(md) {
                padding: 16px 25px;
                padding-right: 40px;
                margin: -16px -25px;
            }

            span {
                @extend .smaller;
                color: $neutral-darker;

                svg {
                    position: relative;
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    margin: 0;
                    margin-bottom: -2px;
                    right: auto;
                    margin-right: 0.25rem;
                }
            }

            svg {
                width: 26px;
                height: 26px;
                position: absolute;
                right: -10px;
                @include media-breakpoint-up(md) {
                    right: 0;
                }

                top: 50%;
                margin-top: -13px;
            }
        }
    }

    .message-sender {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 3px;
        color: #6c757d;
        margin-left: $chat-bubble-padding-x;
    }

    .message-content {
        max-width: 80%;
        float: left;

        @include media-breakpoint-up(md) {
            max-width: 75%;
        }
    }

    .message-body {

        position: relative;

        border-radius: $chat-bubble-radius;

        box-shadow: none;
        padding: $chat-bubble-padding-y $chat-bubble-padding-x;
        width: 100%;
        overflow: hidden;
        font-weight: $font-weight-normal;
        font-size: 1.5rem;
        line-height: 1.38;

        backface-visibility: visible;
        -webkit-backface-visibility: visible;
        transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
        opacity: 1;

        .list-group-item {
            color: $black;
        }

        img {
            max-width: calc(100% + 40px);
            margin: #{-$chat-bubble-padding-y} #{-$chat-bubble-padding-x};
        }
    }

    .message-avatar {
        align-self: end;

        .avatar {
            width: 26px;
            height: 26px;
        }
    }

    &.sender-user {
        transform-origin: right bottom;

        .message-sender {
            display: none;
        }

        .message-body {
            float: right;
            font-weight: $font-weight-normal;

            background: $white;
            color: $black;
            box-shadow: $message-box-shadow !important;
            border: $border-width $border-style $border-color;

            &:after {
                border-width: 0 11px 8px 0;
                border-color: transparent $white transparent transparent;
                left: inherit;
                right: 8px;
            }
        }

        .message-wrapper {
            flex-direction: row-reverse;
        }

        .message-avatar {
            margin-left: 5px;
        }
    }

    &.sender-server {
        transform-origin: left bottom;

        .message-body {
          background: $neutral-lighter;
          color: $neutral-darker;
        }

        .message-avatar {
            margin-right: 5px;
        }
    }

    &.is-error .message-content {
        color: $negative-base;
        font-weight: $font-weight-bold;
    }
}
