// Breadcrumbs

$breadcrumb-padding-y:              .75rem !default;
$breadcrumb-padding-x:              1rem !default;
$breadcrumb-item-padding:           1rem !default;

$breadcrumb-margin-bottom:          1rem !default;

 
$breadcrumb-divider-color:          $neutral-dark;
$breadcrumb-active-color:           $primary-base !default;
// $breadcrumb-divider:                quote("/") !default;

$breadcrumb-border-radius:          $border-radius !default;

