// =Palette

$black:             #030612;
$white:             #FFFFFF;

$primary-darker:    #0A0F22;
$primary-dark:      #0050B0;
$primary-base:      #0062D6;
$primary-light:     #D6E5F8;
$primary-lighter:   #EBF3FC;
$primary-lightest:   #f6f9fd;

$neutral-darker:    #2A2D3C;
$neutral-dark:      #3F424A;
$neutral-base:      #7C7C83;
$neutral-mid:       #CDCDD0;
$neutral-light:     #E4E6E8;
$neutral-lighter:   #EDEDEE;
$neutral-lightest:  #F9F9FA;

$negative-darker:   #A30109;
$negative-dark:     #E6000A;
$negative-base:     #FF424D;
$negative-mid:      #FFA0A6;
$negative-light:    #FFC6C9;
$negative-lighter:  #FFECED;
$negative-lightest: #FFF5F6;

$positive-darker:   #106939;
$positive-dark:     #1B9D56;
$positive-base:     #1FC267;
$positive-mid:      #84DEAC;
$positive-light:    #B1EACA;
$positive-lighter:  #E8F8EF;
$positive-lightest: #F4FCF7;

$yellow-darker:     #7B6100;
$yellow-dark:       #ECC01E;
$yellow-base:       #F9C918;
$yellow-mid:        #FBDF74;
$yellow-light:      #FDE9A3;
$yellow-lighter:    #FEF7DD;
$yellow-lightest:   #FEFCF3;


// $pastel-blue:       #93DDEA;
// $pastel-orange:     #EFD6AC;
// $pastel-purple:     #ACADEF;
// $pastel-rose:       #F2C5CB;
// $pastel-green:      #BCF1D4;
// $pastel-lime:       #E2EFAC;
// $pastel-yellow:     #EFE8AC;
// $pastel-red:        #EFBFAC;


$pastel-blue:           #8ABADE;
$pastel-petrol:         #689AAB;
$pastel-mint-light:     #A9DAD1;
$pastel-mint:           #90CFB5;  
$pastel-mint-dark:      #88BBAA;
$pastel-green:          #BBDCAD;
$pastel-olive:          #BACAB2;
$pastel-yellow:         #FCEE91;
$pastel-lime:           #E0E78E;
$pastel-yellow-light:   #F4F1B0;
$pastel-brown:          #CCAC90;
$pastel-rose:           #FBDEDE;
$pastel-rose-dark:      #F8B2BC;
$pastel-orange-dark:    #F9A586;
$pastel-red:            #EFA796;
$pastel-orange:         #FEBD7D;
$pastel-violet:         #CDB3D5;
$pastel-purple:         #B3B4DA;



