// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  color: $black;
  text-align: center;
  text-decoration: none;
  white-space: $btn-white-space;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;


  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);

  &.loading {

    cursor: default;
    pointer-events: none;

    &:before {
      content: '';
      margin-right: .8rem;
      margin-left: -.8rem;
      width: 2.2rem;
      height: 2.2rem;
      display: inline-block;
      position: relative;
      background-size: cover;
      vertical-align: text-bottom;
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cpath fill='%23fff' d='M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E");
    }
    
  }
  


  &:focus,
  &.focus {
    outline: 0;
    // box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    box-shadow: none !important;
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;

    &:active,
    &.active {
      // @include box-shadow($btn-active-box-shadow);
      &:focus {
        // @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
      }
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}


// //
// // Alternate buttons
// //

.btn-primary {
  @include button-variant($color: $white, $background: $primary-base, $border: $primary-base, $hover-background: $primary-dark);
}

.btn-secondary {
  @include button-variant($color: $black, $background: $white, $border: $neutral-mid, $hover-border: $neutral-mid,  $hover-background: $neutral-lightest);

  &.loading {

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cpath fill='%23030612' d='M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E");
    }
    
  }
}

.btn-dark {
  @include button-variant($color: $white, $background: $neutral-darker, $border: $neutral-darker, $hover-border: $neutral-darker, $hover-background: $black);
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}


//
// Button Sizes
//

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
  &.loading {
    &:before {
      margin-right: .4rem;
      margin-left: -.4rem;
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}


.btn-round {
  border-radius: 90px;
}

.btn-circle {
  border-radius: 150px;
  width: 42px;
  height: 42px;
  padding-left: 0;
  padding-right: 0;
}

.btn-sm.btn-circle {
  width: 33px;
  height: 33px;
}

.btn-lg.btn-circle {
  width: 54px;
  height: 54px;
}

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
