// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               .8rem;
$btn-padding-x:               2rem;
$btn-font-family:             $font-family-sans-serif;
$btn-font-size:               $font-size-base;
$btn-line-height:             $line-height-base;
$btn-white-space:             null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            .6rem;
$btn-padding-x-sm:            1.4rem;
$btn-font-size-sm:            $font-size-sm;
$btn-line-height-sm:          $line-height-sm;

$btn-padding-y-lg:            .8rem;
$btn-padding-x-lg:            3rem;
$btn-font-size-lg:            $font-size-lg;
$btn-line-height-lg:          $line-height-lg;

$btn-border-width:            $border-width;

$btn-font-weight:             $font-weight-bold;
$btn-box-shadow:              none;
$btn-focus-width:             0;
$btn-focus-color:             $primary-dark;
$btn-focus-box-shadow:        none;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       none;

$btn-link-disabled-color:     $neutral-base !default;

$btn-block-spacing-y:         .5rem !default;

$btn-border-radius:           $border-radius;
$btn-border-radius-lg:        $border-radius-lg;
$btn-border-radius-sm:        $border-radius-sm;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

