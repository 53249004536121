$box-shadow-xs:         0 .4rem .6rem -.4rem rgba($neutral-dark, 0.15); // , 0 0 0 .1rem $neutral-lighter;
$box-shadow-xs-hover:   0 .4rem .6rem -.4rem rgba($neutral-dark, 0.15); // , 0 0 0 .1rem $neutral-mid;
$box-shadow-sm:         0 .7rem .9rem -.4rem rgba($neutral-dark, 0.1); // , 0 0 0 .1rem $neutral-lighter;
$box-shadow-sm-hover:   0 .4rem .6rem -.4rem rgba($neutral-dark, 0.1); // , 0 0 0 .1rem $neutral-mid;

$box-shadow: 0 1.5rem 4rem rgba($neutral-dark, 0.075);

$box-shadow-lg: 0 2.4rem 6.4rem 0 rgba($neutral-dark, .075);



