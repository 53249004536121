// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: .4rem !default; //4x

$spacers: (
  0: 0,
  1: $spacer * 1, // .4rem 4px
  2: $spacer * 2, // .8rem 8px
  3: $spacer * 4, // 1.6rem 16px
  4: $spacer * 6, // 2.4rem 24px
  5: $spacer * 12, // 4.8rem 48px
  6: $spacer * 15, // 6rem 60px
  7: $spacer * 20, // 8rem 80px
  8: $spacer * 35, // 14rem 140px

) !default; 

$negative-spacers: negativify-map($spacers);
// scss-docs-end spacer-variables-maps