// Forms
$label-margin-bottom:                   .4rem;

$input-padding-y:                       .8rem;
$input-padding-x:                       1rem;

$input-font-family:                     $font-family-sans-serif;
$input-font-size:                       $font-size-base;
$input-font-weight:                     $font-weight-normal;
$input-line-height:                     $line-height-base;

$input-padding-y-sm:                    .6rem;
$input-padding-x-sm:                    .8rem;
$input-font-size-sm:                    $font-size-sm;
$input-line-height-sm:                  $line-height-sm;

$input-padding-y-lg:                    .8rem;
$input-padding-x-lg:                    1.6rem;
$input-font-size-lg:                    $font-size-lg;
$input-line-height-lg:                  $line-height-lg;

$input-bg:                              $white;
$input-disabled-bg:                     $neutral-lightest;

$input-color:                           $black;
$input-border-color:                    $neutral-mid;
$input-border-width:                    $border-width;
$input-box-shadow:                      inset 0 1px 1px rgba($black, .075);

$input-border-radius:                   $border-radius;
$input-border-radius-lg:                $border-radius-lg;
$input-border-radius-sm:                $border-radius-sm;

$input-focus-bg:                        $input-bg;
$input-focus-border-color:              $primary-base;
$input-focus-color:                     $input-color;
$input-focus-width:                     0;
$input-focus-box-shadow:                 0 0 0 $input-focus-width $primary-light;

$input-placeholder-color:               $neutral-base;
$input-plaintext-color:                 $black;

$input-height-border:                   $input-border-width * 2 !default;

$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y) !default;
$input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y / 2) !default;

$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm:                       add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg:                       add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$form-text-margin-top:                  .4rem;

$form-check-input-gutter:               2rem;
$form-check-input-margin-y:             .48rem;
$form-check-input-margin-x:             .4rem;

$form-check-inline-margin-x:            .75rem !default;
$form-check-inline-input-margin-x:      .3125rem !default;

$form-grid-gutter-width:                10px !default;
$form-group-margin-bottom:              2rem !default;

$input-group-addon-color:               $input-color !default;
$input-group-addon-bg:                  $white !default;
$input-group-addon-border-color:        $input-border-color !default;

$custom-forms-transition:               background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$custom-control-gutter:                 1.28rem;
$custom-control-spacer-x:               2.56rem;
$custom-control-cursor:                 null;

$custom-control-indicator-size:         1.6rem;
$custom-control-indicator-bg:           $input-bg !default;

$custom-control-indicator-bg-size:      50% 50% !default;
$custom-control-indicator-box-shadow:   $input-box-shadow !default;
$custom-control-indicator-border-color: $neutral-base;
$custom-control-indicator-border-width: $input-border-width !default;

$custom-control-label-color:            null !default;

$custom-control-indicator-disabled-bg:          $input-disabled-bg !default;
$custom-control-label-disabled-color:           $neutral-base !default;

$custom-control-indicator-checked-color:        $white !default;
$custom-control-indicator-checked-bg:           $primary-base !default;
$custom-control-indicator-checked-disabled-bg:  rgba($primary-base, .5);
$custom-control-indicator-checked-box-shadow:   null !default;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg !default;

$custom-control-indicator-focus-box-shadow:     $input-focus-box-shadow !default;
$custom-control-indicator-focus-border-color:   $input-focus-border-color !default;

$custom-control-indicator-active-color:         $white !default;
$custom-control-indicator-active-bg:            $primary-base;
$custom-control-indicator-active-box-shadow:    null !default;
$custom-control-indicator-active-border-color:  $custom-control-indicator-active-bg !default;

$custom-checkbox-indicator-border-radius:       $border-radius-sm !default;
$custom-checkbox-indicator-icon-checked:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>") !default;

$custom-checkbox-indicator-indeterminate-bg:           $primary-base !default;
$custom-checkbox-indicator-indeterminate-color:        $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>") !default;
$custom-checkbox-indicator-indeterminate-box-shadow:   null !default;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg !default;

$custom-radio-indicator-border-radius:          50% !default;
$custom-radio-indicator-icon-checked:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>") !default;

$custom-switch-width:                           $custom-control-indicator-size * 1.75 !default;
$custom-switch-indicator-border-radius:         $custom-control-indicator-size / 2 !default;
$custom-switch-indicator-size:                  subtract($custom-control-indicator-size, $custom-control-indicator-border-width * 4) !default;

$custom-select-padding-y:           $input-padding-y !default;
$custom-select-padding-x:           $input-padding-x !default;
$custom-select-font-family:         $input-font-family !default;
$custom-select-font-size:           $input-font-size !default;
$custom-select-height:              $input-height !default;
$custom-select-indicator-padding:   1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight:         $input-font-weight !default;
$custom-select-line-height:         $input-line-height !default;
$custom-select-color:               $input-color !default;
$custom-select-disabled-color:      $custom-control-label-disabled-color;
$custom-select-bg:                  $input-bg !default;
$custom-select-disabled-bg:         $input-disabled-bg;
$custom-select-bg-size:             8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color:     $neutral-dark;
$custom-select-indicator:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") !default;
$custom-select-background:          escape-svg($custom-select-indicator) no-repeat right $custom-select-padding-x center / $custom-select-bg-size !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-feedback-icon-padding-right: add(1em * .75, (2 * $custom-select-padding-y * .75) + $custom-select-padding-x + $custom-select-indicator-padding) !default;
$custom-select-feedback-icon-position:      center right ($custom-select-padding-x + $custom-select-indicator-padding) !default;
$custom-select-feedback-icon-size:          $input-height-inner-half $input-height-inner-half !default;

$custom-select-border-width:        $input-border-width !default;
$custom-select-border-color:        $input-border-color !default;
$custom-select-border-radius:       $border-radius !default;
$custom-select-box-shadow:          inset 0 1px 2px rgba($black, .075) !default;

$custom-select-focus-border-color:  $input-focus-border-color !default;
$custom-select-focus-width:         $input-focus-width !default;
$custom-select-focus-box-shadow:    0 0 0 $custom-select-focus-width $primary-light !default;

$custom-select-padding-y-sm:        $input-padding-y-sm !default;
$custom-select-padding-x-sm:        $input-padding-x-sm !default;
$custom-select-font-size-sm:        $input-font-size-sm !default;
$custom-select-height-sm:           $input-height-sm !default;

// $custom-select-padding-y-lg:        $input-padding-y-lg !default;
// $custom-select-padding-x-lg:        $input-padding-x-lg !default;
// $custom-select-font-size-lg:        $input-font-size-lg !default;
// $custom-select-height-lg:           $input-height-lg !default;

$custom-range-track-width:          100%;
$custom-range-track-height:         .8rem;
$custom-range-track-cursor:         pointer;
$custom-range-track-bg:             $neutral-light;
$custom-range-track-border-radius:  1.6rem;
$custom-range-track-box-shadow:     none;

$custom-range-thumb-width:                   1.6rem !default;
$custom-range-thumb-height:                  $custom-range-thumb-width !default;
$custom-range-thumb-bg:                      $primary-base !default;
$custom-range-thumb-border:                  0 !default;
$custom-range-thumb-border-radius:           1rem !default;
$custom-range-thumb-box-shadow:              none;
$custom-range-thumb-focus-box-shadow:        none;
$custom-range-thumb-focus-box-shadow-width:  $input-focus-width !default; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg:               $primary-dark;
$custom-range-thumb-disabled-bg:             $neutral-base;

$custom-file-height:                $input-height !default;
$custom-file-height-inner:          $input-height-inner !default;
$custom-file-focus-border-color:    $input-focus-border-color !default;
$custom-file-focus-box-shadow:      $input-focus-box-shadow !default;
$custom-file-disabled-bg:           $input-disabled-bg !default;

$custom-file-padding-y:             $input-padding-y !default;
$custom-file-padding-x:             $input-padding-x !default;
$custom-file-line-height:           $input-line-height !default;
$custom-file-font-family:           $input-font-family !default;
$custom-file-font-weight:           $input-font-weight !default;
$custom-file-color:                 $input-color !default;
$custom-file-bg:                    $input-bg !default;
$custom-file-border-width:          $input-border-width !default;
$custom-file-border-color:          $input-border-color !default;
$custom-file-border-radius:         $input-border-radius !default;
$custom-file-box-shadow:            $input-box-shadow !default;
$custom-file-button-color:          $custom-file-color !default;
$custom-file-button-bg:             $input-group-addon-bg !default;
$custom-file-text: (
  en: "Browse"
) !default;


// Form validation

$form-feedback-margin-top:          $form-text-margin-top !default;
$form-feedback-font-size:           $font-size-sm !default;
$form-feedback-valid-color:         $positive-dark;
$form-feedback-invalid-color:       $negative-dark;

$form-feedback-icon-valid-color:    $form-feedback-valid-color !default;
$form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color !default;
$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;

$form-validation-states: () !default;
$form-validation-states: map-merge(
  (
    "valid": (
      "color": $form-feedback-valid-color,
      "icon": $form-feedback-icon-valid
    ),
    "invalid": (
      "color": $form-feedback-invalid-color,
      "icon": $form-feedback-icon-invalid
    ),
  ),
  $form-validation-states
);
