$zindex-dropdown:                   1000 !default;
$dropdown-min-width:                10rem !default;
$dropdown-padding-x:                0 !default;
$dropdown-padding-y:                .5rem !default;
$dropdown-spacer:                   .125rem !default;
$dropdown-font-size:                $font-size-base !default;
$dropdown-color:                    $black !default;
$dropdown-bg:                       $white !default;
$dropdown-border-color:             rgba($black, .15) !default;
$dropdown-border-radius:            $border-radius !default;
$dropdown-border-width:             $border-width !default;
$dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width) !default;
$dropdown-divider-bg:               $neutral-light !default;
$dropdown-divider-margin-y:         .5rem !default;
$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175) !default;

$dropdown-link-color:               $neutral-dark !default;
$dropdown-link-hover-color:         darken($neutral-dark, 5%) !default;
$dropdown-link-hover-bg:            $neutral-light !default;

$dropdown-link-active-color:        $black !default;
$dropdown-link-active-bg:           $white !default;

$dropdown-link-disabled-color:      $neutral-base !default;

$dropdown-item-padding-y:           .25rem !default;
$dropdown-item-padding-x:           1.5rem !default;

$dropdown-header-color:             $neutral-base !default;
$dropdown-header-padding:           $dropdown-padding-y $dropdown-item-padding-x !default;