.border         { border: $border-width solid $border-color !important; }
.border-top     { border-top: $border-width solid $border-color !important; }
.border-right   { border-right: $border-width solid $border-color !important; }
.border-bottom  { border-bottom: $border-width solid $border-color !important; }
.border-left    { border-left: $border-width solid $border-color !important; }

.border-0        { border: 0 !important; }
.border-top-0    { border-top: 0 !important; }
.border-right-0  { border-right: 0 !important; }
.border-bottom-0 { border-bottom: 0 !important; }
.border-left-0   { border-left: 0 !important; }

.border {
  border: $border-width $border-style $border-color;
}

.border.hover:hover {
  border: $border-width $border-style $border-color-hover;
}

.border-radius {
  border-radius: $border-radius;
}

.border-radius-sm {
  border-radius: $border-radius-sm;
}

.border-radius-lg {
  border-radius: $border-radius-lg;
}

.border-none {
  border: none !important;
}