@mixin alert-variant($background, $border, $color) {
  color: $color;
  background: $background;
  border-color: $border;

  hr {
    background-color: rgba($color, .5);
  }

  .alert-link {
    color: $color;
  }

  .close {
    color: $color;
    &:hover {
      color: $color;
    }
  }
}
