//
// Base styles
//

a.card,
a>.card,
.card.card-hover {
  text-decoration: none;
  transition: box-shadow .3s ease-in-out;
  color: inherit;
  cursor: pointer;

  p, ul, ol {
    color: $black;
  }

  &:hover {
    border: $border-width $border-style $border-color-hover;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  height: $card-height;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  box-shadow: $card-box-shadow;

  @include border-radius($card-border-radius);


  >hr {
    margin-right: 0;
    margin-left: 0;
  }

  >.list-group {
    border-top: inherit;
    border-bottom: inherit;

    &:first-child {
      border-top-width: 0;
      @include border-top-radius($card-inner-border-radius);
    }

    &:last-child {
      border-bottom-width: 0;
      @include border-bottom-radius($card-inner-border-radius);
    }
  }

  // Due to specificity of the above selector (`.card > .list-group`), we must
  // use a child selector here to prevent double borders.
  >.card-header+.list-group,
  >.list-group+.card-footer {
    border-top: 0;
  }
}

.card-lg {
  border: none;
  box-shadow: $card-box-shadow-lg;
}

.card-xl {
  border: none;
  box-shadow: $card-box-shadow-xl;
}


.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  // Workaround for the image size bug in IE
  // See: https://github.com/twbs/bootstrap/pull/28855
  min-height: 1px;
  padding: $card-spacer-x;
  color: $card-color;

  &.card-body-list {
    padding: 0;

    & .list-group-item {
      border-left: none;
      border-right: none;
      border-top: none;
      box-shadow: none;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .card-img-top {
    margin-left: -$card-spacer-x;
    margin-top: -$card-spacer-x;
    margin-right: -$card-spacer-x;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.card-sm > .card-body {
  padding: $card-spacer-y-sm $card-spacer-x-sm;
}

.card-lg > .card-body {
  padding: $card-spacer-y-lg $card-spacer-x-lg;
  @include media-breakpoint-down(md) {
    padding: $card-spacer-y $card-spacer-x;
  }
}


.card-xl > .card-body {
  
  padding: $card-spacer-y $card-spacer-x;

  @include media-breakpoint-up(md) {
    padding: $card-spacer-y-xl $card-spacer-x-xl;
  }

  > .card-img-top {
    margin: -$card-spacer-y -$card-spacer-x 0 -$card-spacer-x;

    @include media-breakpoint-up(md) {
      margin: -$card-spacer-y-xl -$card-spacer-x-xl 0 -$card-spacer-x-xl;
    }
  }
}

.card-title {
  margin-bottom: 1.6rem;
}

.card-subtitle {
  margin-top: -$card-spacer-y / 2;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  @include hover() {
    text-decoration: none;
  }

  +.card-link {
    margin-left: $card-spacer-x;
  }
}

//
// Optional textual caps
//

.card-header, .card-xl .card-header  {
  padding: $card-spacer-y/1.5 $card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  color: $card-cap-color;
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }

  &:last-child {
    border-bottom: none;
  }
}

.card-xl .card-header {
  padding: $card-spacer-y-xl/1.5 $card-spacer-x-xl;
  padding-bottom: 0
}

.card-header-title {
  margin-bottom: 0 !important;
}


.card-lg .card-header {
  padding: $card-spacer-y-lg $card-spacer-x-lg;

  @include media-breakpoint-down(md) {
    padding: $card-spacer-y $card-spacer-x;
  }
}


.card-footer {
  padding: $card-spacer-y/1.5 $card-spacer-x;
  color: $card-cap-color;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }
  
}


.card-lg .card-footer {
  padding: $card-spacer-y-lg $card-spacer-x-lg;
  
  @include media-breakpoint-down(md) {
    padding: $card-spacer-y $card-spacer-x;
  }
}


//
// Header navs
//

.card-header-tabs {
  margin-right: -$card-spacer-x / 2;
  margin-bottom: -$card-spacer-y;
  margin-left: -$card-spacer-x / 2;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -$card-spacer-x / 2;
  margin-left: -$card-spacer-x / 2;
}

// Card image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
  @include border-radius($card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  // margin-left: -$card-spacer-x;
  // margin-top: -$card-spacer-x;
  // margin-right: -$card-spacer-x;
  flex-shrink: 0; // For IE: https://github.com/twbs/bootstrap/issues/29396
  width: calc(100%); // Required because we use flexbox and this inherently applies align-self: stretch
}

.card-img,
.card-img-top {
  @include border-top-radius($card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  @include border-bottom-radius($card-inner-border-radius);
}


// Card deck

.card-deck {
  .card {
    margin-bottom: $card-deck-margin;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-right: $card-deck-margin;
      margin-bottom: 0; // Override the default
      margin-left: $card-deck-margin;
    }
  }
}


//
// Card groups
//

.card-group {

  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  >.card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;

    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    >.card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      +.card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners

      &:not(:last-child) {
        @include border-right-radius(0);

        .card-img-top,
        .card-header {
          // stylelint-disable-next-line property-disallowed-list
          border-top-right-radius: 0;
        }

        .card-img-bottom,
        .card-footer {
          // stylelint-disable-next-line property-disallowed-list
          border-bottom-right-radius: 0;
        }
      }

      &:not(:first-child) {
        @include border-left-radius(0);

        .card-img-top,
        .card-header {
          // stylelint-disable-next-line property-disallowed-list
          border-top-left-radius: 0;
        }

        .card-img-bottom,
        .card-footer {
          // stylelint-disable-next-line property-disallowed-list
          border-bottom-left-radius: 0;
        }
      }

    }
  }
}


//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $card-columns-margin;
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}
