// Avatar

$avatar-size-base:  4.8rem;
$avatar-size-xs:    2.4rem;
$avatar-size-sm:    3.6rem;
$avatar-size-lg:    6.4rem;
$avatar-size-xl:      8rem;
$avatar-size-xxl:  14.4rem;

$avatar-title-bg:    $neutral-light;
$avatar-title-color: $black;

