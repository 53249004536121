.box-shadow-sm {
  box-shadow: $box-shadow-sm !important;
}

.box-shadow, .box-shadow-md {
  box-shadow: $box-shadow !important;
}

.box-shadow-lg {
  box-shadow: $box-shadow-lg !important;
}

.box-shadow-none {
  box-shadow: none !important;
}